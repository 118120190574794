

/* chat ui css  */

#chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #1d5b79;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  #chat-icon img {
    width: 20px;
    height: 20px;
  }
  
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    margin-right: 15px;
    padding: 15px;
    border: 1px solid #888;
    height: 66%;
    width: 40%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .chat-messages {
    height: 83%;
    overflow-y: auto;
  }
  
  .chat-messages-doctor {
    height: 350px;
    overflow-y: auto;
  }
  
  .message-input {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    border: 1px solid black;
    border-radius: 10px;
  }
  
  .message-input input {
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    border: none;
  }
  
  .message-input input:focus {
    outline: none;
  }
  
  .message-input button {
    /* padding: 10px; */
    background-color: white;
    color: #007bff;
    border: none;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .show {
    display: block;
  }
  
  .chat-container {
    display: flex;
    height: 95%;
  }
  
  .patient-container {
    flex: 0.4;
    border-right: 1px solid grey;
    padding: 3px;
    overflow: auto;
  }
  
  .default-screen {
    flex: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .patient-profile {
    display: flex;
    border-bottom: 1px solid grey;
    padding: 3px;
  }
  
  .patient-name {
    flex: 0.8;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 5px;
    font-weight: 500;
    font-size: 15px;
  }
  
  .patient-image {
    flex: 0.2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .patient-chat {
    flex: 0.6;
  }
  /* Styling for the message */
  .message {
    background-color: #e6e5e5;
    border-radius: 10px;
    width: fit-content;
    max-width: 80%; /* Ensure it doesn’t span full width */
    min-width: 100px;
    padding: 10px;
    margin-bottom: 5px;
    word-wrap: break-word;
    position: relative; /* Required for timestamp positioning */
    display: inline-flex; /* Keeps single-line content aligned with timestamp */
    flex-direction: column; /* Aligns content vertically */
    min-height: 50px;
  }
  
  /* Container for each message */
  .message-container {
    margin: 5px 5px 15px 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  /* Styling for the timestamp */
  .time-stamp {
    font-size: 12px;
    color: #666;
    margin-top: 3px;
    align-self: flex-end; /* Align timestamp to bottom-right */
    white-space: nowrap;
    position: absolute; /* Places timestamp at the bottom right */
    bottom: 0px;
    right: 10px;
  }
  
  /* Styling for reply container */
  .reply-container {
    background-color: #bbbbbbef;
    border-radius: 8px;
    padding: 8px;
    margin-top: 5px;
    width: fit-content;
    max-width: 80%;
    min-width: 100px;
    word-wrap: break-word;
    align-self: flex-start; /* Align reply to the left */
    position: relative; /* Required for timestamp positioning */
    display: flex;
    flex-direction: column;
    min-height: 60px;
  }
  
  /* Label for doctor's response */
  .message-label {
    font-size: 10px;
    color: #1f2937;
    margin-bottom: 4px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  /* Styling for the reply */
  .reply {
    font-size: 14px;
    color: #333;
    padding-right: 30px; /* Add space for the timestamp */
  }
  
  
  .time-stamp{
    font-size: 10px;
  }