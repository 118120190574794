@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:wght@200..1000&family=Open+Sans:wght@300..800&display=swap');

* {
  font-family: "Montserrat", serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.rmdp-container {
  width: 100% !important;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari, and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* For WebKit browsers (Chrome, Safari) */
    ::-webkit-scrollbar {
      width: 15px;
    }

    ::-webkit-scrollbar-track {
      background-color: #1d5b79;
      padding: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #fbffff;
      transition: height 0.5s ease-in;
      border: 5px solid #1d5b79;
    }

    .calendar-container {
      width: 900px;
      height: 600px;
      /* Default width for laptop and desktop */
    }

    @media (max-width: 1024px) {
      .calendar-container {
        height: 600px;
        width: 600px; /* Adjust for tablets */
      }
    }

    @media (max-width: 768px) {
      .calendar-container {
        width: 300px; /* Adjust for phones */
      }
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
    .activeTab::before {
      content: "";
      position: absolute;
      margin-left: 163px;
      margin-right: 3px;
      background-color: transparent;
      height: 20px;
      width: 50px;
      border-radius: 0px 0px 20px 0px;
      top: -20px;
      box-shadow: 25px 8px 0 0 white;
    }

    .activeTab::after {
      content: "";
      position: absolute;
      margin-left: 163px;
      margin-right: 3px;
      background-color: transparent;
      height: 20px;
      width: 50px;
      border-radius: 0px 20px 0px 0px;
      bottom: -20px;
      box-shadow: 25px -8px 0 0 white;
    }

    .activeTab-2::before {
      content: "";
      position: absolute;
      margin-left: 32px;
      margin-right: 3px;
      background-color: transparent;
      height: 20px;
      width: 20px;
      border-radius: 0px 0px 20px 0px;
      top: -20px;
      box-shadow: 5px 6px 0 0 white;
    }
    .activeTab-2::after {
      content: "";
      position: absolute;
      margin-left: 32px;
      margin-right: 3px;
      background-color: transparent;
      height: 20px;
      width: 20px;
      border-radius: 0px 20px 0px 0px;
      bottom: -20px;
      box-shadow: 5px -6px 0 0 white;
    }
    /* In your CSS file or Tailwind configuration */
    .text-custom-color {
      color: #1d5b79;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    ::selection {
      color: #d9edf7;
      background: #12374a;
    }
  }
}
